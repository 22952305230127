import React from 'react';
import styled from 'styled-components';

const FooterContainer = styled.div`
  padding: 20px;
  background-color: #003366;
  color: white;
  text-align: center;
`;

const Mylink = styled.a`
  color: white;
`;

const Footer = () => {
  return (
    <FooterContainer>
<p>&copy; 2024 Delfind OG. All rights reserved. This site is under development.</p>
<Mylink href="https://www.freepik.com/free-psd/blank-smartphone-mockup-isolate-background_59515008.htm#query=phone%20mockup%20png&position=2&from_view=keyword&track=ais_hybrid&uuid=6461c7ae-e2e4-4e38-98e0-e266124729e3">Image by vector_corp</Mylink> on Freepik
    </FooterContainer>
  );
};

export default Footer;
