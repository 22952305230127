import React from 'react';
import styled from 'styled-components';
import { FaEnvelope, FaPhone, FaMapMarkerAlt } from 'react-icons/fa'; // Import icons
import { useTranslation } from 'react-i18next';
import './../i18n'; 

const ContactContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align:center;
  flex-direction: column;
  padding: 5rem;
  flex-grow: 1;
  font-family: 'Poppins', sans-serif; /* Modern font */
  max-width: 1200px;
  margin: 0 auto;

  @media (max-width: 1024px) {
    padding: 1rem;
  }
`;

const Title = styled.h1`
  font-size: 48px;
  margin-bottom: 20px;
  background: -webkit-linear-gradient(#0C9EEC, #6F7AEB);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1);
  
  @media (max-width: 1024px) {
    font-size: 36px;
  }
`;

const SubTitle = styled.p`
  font-size: 22px;
  margin-bottom: 40px;
  color: #555;
  text-align: center;

  @media (max-width: 1024px) {
    font-size: 18px;
  }
`;

const ContactInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 5rem;
  gap: 20px;
`;

const InfoItem = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 18px;
  color: #555;

  svg {
    color: #6F7AEB; /* Consistent icon color */
    font-size: 24px;
  }

  @media (max-width: 1024px) {
    font-size: 16px;
  }
`;

const Contact = () => {
  const { t } = useTranslation();
  return (
    <ContactContainer id='contact'>
      <Title>{t('contact.title')}</Title>
      <SubTitle>{t('contact.sub')}</SubTitle>
      <ContactInfo>
        <InfoItem>
          <FaEnvelope />
          <span>info@delfind.net</span>
        </InfoItem>
      </ContactInfo>
    </ContactContainer>
  );
};

export default Contact;
