import React from 'react';
import Header from './components/Header';
import Hero from './components/Hero';
import Footer from './components/Footer';
import Contact from './components/Contact';
import About from './components/About';
import Features from './components/Features';
import styled from 'styled-components';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const MyRoutes = styled.div`

`;

const App = () => {
  return (
    <Router>
    <AppContainer>
      <Header />
      <MyRoutes>
      <Hero /><About /><Features /><Contact />
      </MyRoutes>
      <Footer />
    </AppContainer>
    </Router>
  );
};

export default App;
