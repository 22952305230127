import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore'; // Explicitly import Firestore

const firebaseConfig = {
    apiKey: "AIzaSyDlOMPmndGEByCqpgvFoK5HNHeS_sG39DE",
    authDomain: "delfind-website.firebaseapp.com",
    projectId: "delfind-website",
    storageBucket: "delfind-website.appspot.com",
    messagingSenderId: "22713942376",
    appId: "1:22713942376:web:6ce3d2b62035b4664e16dd",
    measurementId: "G-E5J5GYS3D5"
  };
  

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
const firestore = getFirestore(app);

export { firestore };