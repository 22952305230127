import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import './../i18n'; 

const AboutContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align:center;
  align-items: center;
  padding: 5rem;
  flex-grow: 1;
  font-family: 'Poppins', sans-serif; /* Modern font */
  max-width: 1200px;
  margin: 0 auto;

  @media (max-width: 1024px) {
    padding: 1rem;
  }
`;

const Title = styled.h1`
  font-size: 48px;
  margin-bottom: 20px;
  background: -webkit-linear-gradient(#0C9EEC, #6F7AEB);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1);
  
  @media (max-width: 1024px) {
    font-size: 36px;
  }
`;

const SubTitle = styled.p`
  font-size: 22px;
  margin-bottom: 40px;
  color: #555;
  text-align: center;

  @media (max-width: 1024px) {
    font-size: 18px;
  }
`;

const SectionTitle = styled.h2`
  margin-top: 5vh;
  font-size: 28px;
  color: #6F7AEB; /* Consistent color with button from Hero */
  font-weight: 600;

  @media (max-width: 1024px) {
    font-size: 24px;
  }
`;

const Paragraph = styled.p`
  font-size: 18px;
  max-width: 800px;
  color: #555;
  text-align: center;
  line-height: 1.6;
  margin-top: 1rem;
  margin-bottom: 3rem;

  @media (max-width: 1024px) {
    font-size: 16px;
  }
`;

const About = () => {
  const { t } = useTranslation();
  return (
    <AboutContainer id='about'>
      <Title>{t('about.title')}</Title>
      <SubTitle>{t('about.sub')}</SubTitle>

      <SectionTitle>{t('about.vision.title')}</SectionTitle>
      <Paragraph>{t('about.vision.description')}</Paragraph>

      <SectionTitle>{t('about.story.title')}</SectionTitle>
      <Paragraph>{t('about.story.description')}</Paragraph>
  
    </AboutContainer>
  );
};

export default About;
