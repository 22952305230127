import React, { useState } from "react";
import styled from "styled-components";
import heroImage from "../assets/mascot.png"; // Add your hero image here
import { useTranslation } from 'react-i18next';
import { FaAndroid } from 'react-icons/fa'; 
import './../i18n'; 

const HeaderContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  display: flex;
  background-color: white;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 4rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-family: 'Poppins', sans-serif;
  @media (max-width: 1024px) {
  padding: 1rem 2rem;
  }
`;

const NavLinks = styled.div`
  display: flex;
  gap: 20px;
  margin-right: 10%; /* Add margin to separate the links from the button */

  @media (max-width: 1024px) {
    display: ${({ isOpen }) => (isOpen ? "flex" : "none")};
    flex-direction: column;
    position: absolute;
    top: 60px;
    right: 0;
    background-color: rgba(34, 0, 51, 0.95); /* Slightly more opaque */
    padding: 20px;
    border-radius: 5px;
  }
`;

const NavLink = styled.a`
  color: black;
  font-weight: 500;
  text-decoration: none;
  padding: 10px 15px;
  border-radius: 5px;
  transition: background-color 0.3s ease, color 0.3s ease;

  &:hover {
    background-color: #0C9EEC;
    color: white;
  }

  @media (max-width: 1024px) {
    color: white;
    &:hover {
      background-color: #00AAFF;
      color: white;
    }
  }
`;

const BurgerMenu = styled.div`
  display: none;
  cursor: pointer;

  @media (max-width: 1024px) {
    display: block;
  }

  div {
    width: 25px;
    height: 3px;
    background-color: black;
    margin: 5px;
    transition: transform 0.3s ease, opacity 0.3s ease;

    &:nth-child(1) {
      transform: ${({ isOpen }) =>
        isOpen ? "rotate(-45deg) translate(-5px, 6px)" : "rotate(0)"};
    }

    &:nth-child(2) {
      opacity: ${({ isOpen }) => (isOpen ? "0" : "1")};
    }

    &:nth-child(3) {
      transform: ${({ isOpen }) =>
        isOpen ? "rotate(45deg) translate(-5px, -6px)" : "rotate(0)"};
    }
  }
`;

const HeroImage = styled.img`
  height: 50px;
  margin-right: 10px;

  @media (max-width: 1024px) {
    height: 40px;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10%;

  @media (max-width: 1024px) {
    margin-left: 0%;
  }
`;

const TitleText = styled.h1`
  color: black;
  font-family: 'Poppins', sans-serif;
  margin: 0;
  font-size: 1.5rem;
  font-weight: 700;
`;

const ButtonWithIcon = styled.button`
  display: flex;
  align-items: center;
  padding: 5px 15px 5px 15px;
  background-color: #6F7AEB;
  color: white;
  border: none;
  border-radius: 2rem;
  cursor: pointer;
  font-size: 18px;
  transition: background-color 0.3s ease, transform 0.2s ease;

  &:hover {
    background-color: #00AAFF;
    transform: scale(1.05); /* Slight button grow on hover */
  }

  svg {
    margin-right: 10px; /* Space between the icon and the text */
  }

  @media (max-width: 1024px) {
    display: inline-flex;
  }
`;

const Header = () => {
  const { t } = useTranslation();
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <HeaderContainer>
      <TitleContainer onClick={() => {window.location.href = "/"}}>
        <HeroImage src={heroImage} alt="App illustration" />
        <TitleText>Delfind</TitleText>
      </TitleContainer>
      <BurgerMenu onClick={toggleMenu} isOpen={menuOpen}>
        <div />
        <div />
        <div />
      </BurgerMenu>
      <NavLinks isOpen={menuOpen}>
        <NavLink href="/">{t('header.home')}</NavLink>
        <NavLink href="#about">{t('header.about')}</NavLink>
        <NavLink href="#features">Features</NavLink>
        <NavLink href="#contact">{t('header.contact')}</NavLink>       
        <ButtonWithIcon>
          <FaAndroid size={24} /> {t('download')}
        </ButtonWithIcon>
      </NavLinks>
    </HeaderContainer>
  );
};

export default Header;
