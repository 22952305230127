// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// Übersetzungsdateien
const resources = {
    en: {
      translation: {
        "home.pretitle":"„Alone we can do so little; together we can do so much.“ – Helen Keller",
        "home.title": "Dive into a Sea of Opportunities",
        "home.subtitle": "With Delfind, finding what you're looking for has never been easier. Create search posts, explore your surroundings, and connect with people nearby. Whether it's experiences, friendships, or opportunities – Delfind takes you where you want to go. Start your journey today!",
        
        "tester.header":"Join our Early Access Program",
        "tester.prompt":"Enter your email address",        
        "tester.commit":"Join Now",
        "tester.feedback":"Thank you for joining! We'll be in touch soon.",
        "tester.gift":" Get exclusive early access features",
        "tester.rocket":" Be the first to explore new updates",
        "tester.envelope":" Receive special offers and support",

        "header.home": "Home",
        "header.about": "About",
        "header.contact": "Contact",
        "download": "Coming soon",

        "email.valid": "Please enter a valid email address",
        "email.error": "Error submitting your email. Please try again later.",

        "about.title": "About Delfind",
        "about.sub": "Connecting Communities, Creating Opportunities",
        "about.vision.title": "Our Vision",
        "about.vision.description": "Our vision at Delfind is to create an app that easily connects people. At its core is the ability to create your own search posts and swipe through the posts of others in your area. With this, we aim to build a platform where everyone can find what they are looking for.",
        "about.story.title": "Our Story",
        "about.story.description": "Delfind was founded in 2024 to help people easily discover new connections and opportunities in their surroundings. What began as an idea has grown into a platform that offers many possibilities.",
  
        "contact.title": "Contact Us",
        "contact.sub": "If you have any questions, feel free to reach out to us!",

        "features.title": "Features of Delfind",
        "features.sub": "Explore the powerful features that make Delfind your ultimate companion for local connections and opportunities.",
        "features.createSearchposts": "Create Searchposts",
        "features.createSearchposts.description": "Easily create searchposts to find whatever you're looking for. Describe what you're searching for, and connect with people around you.",
        "features.swipeExplore": "Swipe & Explore",
        "features.swipeExplore.description": "Swipe through posts from people nearby to see what they are searching for. Discover new opportunities, interact with others, and explore your surroundings.",
        "features.chat": "In-App Chat",
        "features.chat.description": "Chat with others directly through the app once you’ve linked up. This makes communication quick and simple."
      }
    },
    de: {
        translation: {
          "home.pretitle":"„Das Glück ist das einzige, das sich verdoppelt, wenn man es teilt.“ – Albert Schweitzer",
          "home.title": "Tauche ein in ein Meer voller Möglichkeiten",
          "home.subtitle": "Mit Delfind findest du schnell, wonach du suchst. Erstelle Suchposts, entdecke deine Umgebung und vernetze dich mit Menschen in deiner Nähe. Ob Erlebnisse, Freundschaften oder Gelegenheiten – Delfind bringt dich ans Ziel. Starte deine Entdeckungsreise!",
      
          "tester.header":"Nimm teil an unserem Early Access Programm",
          "tester.prompt":"Gib deine Email Adresse ein",        
          "tester.commit":"Jetzt Mitmachen",
          "tester.feedback":"Danke für deine Teilnahme! Wir werden uns bald melden.",
          "tester.gift": "Erhalte exklusiven Frühzugang zu Funktionen",
          "tester.rocket": "Sei die*der Erste, die*der neue Updates erkundet",
          "tester.envelope": "Erhalte spezielle Angebote und Support",

		      "header.home": "Home",
          "header.about": "Über uns",
          "header.contact": "Kontakt",
          "download": "Bald verfügbar",

          "email.valid": "Bitte gib eine gültige Email Adresse ein.",
          "email.error": "Fehler beim Versand. Bitte versuche es später erneut.",
      
          "about.title": "Über Delfind",
          "about.sub": "Connecting Communities, Creating Opportunities",
          "about.vision.title": "Unsere Vision",
          "about.vision.description": "Unsere Vision bei Delfind ist es, eine App zu entwickeln, die Menschen einfach verbindet. Im Zentrum stehen dabei das Erstellen von Suchposts und das Durchswipen der Posts anderer in deiner Umgebung. So möchten wir eine Plattform schaffen, auf der jeder das finden kann, wonach er oder sie sucht.",
          "about.story.title": "Unsere Geschichte",
          "about.story.description": "Delfind wurde 2024 gegründet, um Menschen dabei zu helfen, neue Verbindungen und Möglichkeiten in ihrer Umgebung leicht zu entdecken. Was als Idee begann, hat sich zu einer Plattform entwickelt, die viele Chancen bietet.",
      
          "contact.title": "Kontaktiere uns",
          "contact.sub": "Wenn du Fragen hast, zögere nicht, uns zu kontaktieren!",
      
          "features.title": "Features von Delfind",
          "features.sub": "Entdecke die leistungsstarken Funktionen, die Delfind zu deinem ultimativen Begleiter für lokale Verbindungen und Chancen machen.",
          "features.createSearchposts": "Erstelle Suchposts",
          "features.createSearchposts.description": "Erstelle ganz einfach Suchposts, um genau das zu finden, wonach du suchst. Beschreibe, wonach du suchst, und vernetze dich mit Menschen in deiner Umgebung.",
          "features.swipeExplore": "Swipe & Entdecke",
          "features.swipeExplore.description": "Swipe durch die Posts von Menschen in deiner Nähe, um zu sehen, wonach sie suchen. Entdecke neue Möglichkeiten, interagiere mit anderen und erkunde deine Umgebung.",
          "features.chat": "In-App Chat",
          "features.chat.description": "Chatte direkt über die App mit anderen, sobald ihr euch verbunden habt. Das macht die Kommunikation schnell und einfach."
        }
      }      
};

i18n
  .use(LanguageDetector) // Automatische Spracherkennung
  .use(initReactI18next) // Verbindung mit React
  .init({
    resources,
    fallbackLng: 'en', // Standard, falls die Sprache nicht erkannt wird
    interpolation: {
      escapeValue: false // React schützt vor XSS
    }
  });

export default i18n;
