import React, { useState } from 'react';
import styled from 'styled-components';
import heroImage from '../assets/mockup.png'; 
import { FaAndroid, FaEnvelope, FaGift, FaRocket } from 'react-icons/fa'; 
import { useTranslation } from 'react-i18next';
import './../i18n'; 
import { firestore } from './../firebase'; // Import Firestore config
import { collection, addDoc, serverTimestamp } from 'firebase/firestore'; 

const HeroContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;
  margin-top: 10vh;
  margin-bottom: 5vh;
  flex-grow: 1;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  font-family: 'Poppins', sans-serif;

  @media (max-width: 1024px) {
    margin:0;
    margin-top: 5vh;
    flex-direction: column;
    padding:10vw;
  }
`;

const TextContent = styled.div`
  max-width: 600px;
  margin-right: 50px;
  text-align: left;

  @media (max-width: 1024px) {
    margin:0;
    max-width: 100%;
    text-align: center;
  }
`;

const PreTitle = styled.p`
  font-size: 18px;
  color: #777;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  margin-bottom: 20px;

  @media (max-width: 1024px) {
    font-size: 14px;
  }
`;

const Title = styled.h1`
  font-size: 48px;
  margin-bottom: 20px;
  background: -webkit-linear-gradient(#0C9EEC, #6F7AEB);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1);

  @media (max-width: 1024px) {
    font-size: 36px;
  }
`;

const SubTitle = styled.p`
  font-size: 22px;
  margin-bottom: 20px;
  color: #555;
  line-height: 1.6;

  @media (max-width: 1024px) {
    font-size: 18px;
  }
`;

const BenefitList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 20px 0;
  font-size: 18px;

  li {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    color: #444;

    svg {
      color: #6F7AEB;
      margin-right: 10px;
    }
  }
    
  @media (max-width: 1024px) {
    font-size: 12px;
  }
`;

const HeroImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  transform: translateY(0);
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-10px);
  }

  @media (max-width: 1024px) {
    width: 100%;
    margin-top: 20px;
  }
`;

const HeroImage = styled.img`
  width: 75%;
  max-width: 350px;
  height: auto;

  @media (max-width: 1024px) {
    width: 100%;
    margin-top: 1rem;
  }
`;

const FormWrapper = styled.div`
  text-align: left;
  padding-top: .5rem;
  width: auto;

  @media (max-width: 1024px) {
    margin:0;
    text-align: center;
  }
`;

const Input = styled.input`
  width: 60%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  margin-right: 10px;

  @media (max-width: 1024px) {
    width:90%;
    margin-bottom: 10px;
    margin-right: 0;
    margin-left: 0;
  }
`;

const SubmitButton = styled.button`
  padding: 10px 20px;
  background-color: #6F7AEB;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease, transform 0.2s ease;

  &:hover {
    background-color: #00AAFF;
    transform: scale(1.05);
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

const ErrorMessage = styled.p`
  color: red;
  font-size: 16px;
  margin-top: 10px;
`;

const SuccessMessage = styled.p`
  color: green;
  font-size: 16px;
  margin-top: 10px;
`;

const Hero = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateEmail(email)) {
      setError(t('email.valid'));
      return;
    }

    setLoading(true);
    setError('');

    try {
      // Save the email to Firestore using addDoc
      await addDoc(collection(firestore, 'testers'), {
        email,
        createdAt: serverTimestamp(),
      });

      setSubmitted(true);
    } catch (error) {
      setError(t('email.error'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <HeroContainer>
      <TextContent>
        <PreTitle>{t('home.pretitle')}</PreTitle>
        <Title>{t('home.title')}</Title>
        <SubTitle>{t('home.subtitle')}</SubTitle>

        <FormWrapper>
          <h3>{t('tester.header')}</h3>
          {!submitted ? (
            <form onSubmit={handleSubmit}>
              <Input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder={t('tester.prompt')}
                required
              />
              <SubmitButton type="submit" disabled={loading}>
                {loading ? 'Submitting...' : t('tester.commit')}
              </SubmitButton>
              {error && <ErrorMessage>{error}</ErrorMessage>}
            </form>
          ) : (
            <SuccessMessage>{t('tester.feedback')}</SuccessMessage>
          )}
        </FormWrapper>       
        <BenefitList>
          <li><FaGift />{t('tester.gift')}</li>
          <li><FaRocket />{t('tester.rocket')}</li>
          <li><FaEnvelope />{t('tester.envelope')}</li>
        </BenefitList>

      </TextContent>

      <HeroImageWrapper>
        <HeroImage src={heroImage} alt="App illustration" />
      </HeroImageWrapper>
    </HeroContainer>
  );
};

export default Hero;
